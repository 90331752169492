.email {
    p {
        color: red;
    }
    table {
        table-layout: fixed;

        td {
            padding: 10px;
        }
        tr td:first-child {
            width: 100px;
        }
    }
}